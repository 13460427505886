import { useEffect, useState } from "react";
import usePost from "../Hook/usePost";
import { ToastMessage } from "./Config";
import { CrossIcon1, GrayStarIcon, YellowStartIcon } from "./Icons";


const FeedbackModal = ({ onClose,milestone,User,type }) => {
    const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: FeedBackData,loading:FeedBackLoading, 
    error: FeedBackError, postData: postFeedBackData } = usePost(`hiring/feedback-banker`);
    const { data: BankerFeedBackData,loading:BankerFeedBackLoading, 
        error: BankerFeedBackError, postData: postBankerFeedBackData } = usePost(`hiring/submit-feedback-banker`);
        const { data: CompanyFeedBackData,loading:CompanyFeedBackLoading, 
            error: CompanyFeedBackError, postData: postCompanyFeedBackData } = usePost(`hiring/submit-feedback-company`);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [error,setError]=useState("");

  const handleRatingClick = (rate) => {
      setRating(rate);
  };

  const handleSubmit = () => {
    if(rating===0 && feedback===""){
setError("Your feedback matters, Please type it above")
    }else{
        setError("")

        if(milestone){
          const obj={
              "banker_rating": rating,
              "banker_feedback_comment": feedback,
              "company_user":User,
              "milestone":milestone?.id
                }
                postFeedBackData(obj,token);
        }
        if(type==="Banker"){
       const obj={
          banker_feedback_comment:feedback,
          rating:rating,
       }
       postBankerFeedBackData(obj,token)
        }
        if(type==="Company"){
          const obj={
              banker_feedback_comment:feedback,
              rating:rating,
           }
          postCompanyFeedBackData(obj,token)
        }
    }
      // handle the feedback submission
     


    //   onClose();
  };
useEffect(()=>{
    if(FeedBackData || BankerFeedBackData || CompanyFeedBackData){
        if(type==="Banker" || type==='Banker1'){
            ToastMessage("success","Thank you for your feedback! It means a lot to us. We strive relentlessly to improve your user experience on our platform")
        }
        if(type==="Company"){
            ToastMessage("success","Thank you for your feedback! Your feedback means a lot to us as it helps us improve the quality of our services.")
        }
    
        onClose();
    }
    if(FeedBackError || BankerFeedBackError || CompanyFeedBackError){
        let text="";
        if(FeedBackError){
            text=FeedBackError?.response?.data?.message
        }
        if(BankerFeedBackError){
            text=BankerFeedBackError?.response?.data?.message
        }
        if(CompanyFeedBackError){
            text=CompanyFeedBackError?.response?.data?.message
        }
        ToastMessage("error",text || "Something went wrong")
    
    }
},[FeedBackData,FeedBackError,BankerFeedBackData,CompanyFeedBackData,BankerFeedBackError,CompanyFeedBackError])
  return (
      <div className="fixed inset-0 px-4 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg py-5 px-4 w-full md:w-[590px] relative">
              <div className="flex justify-end">
                  <button onClick={onClose} className=''>
                     <CrossIcon1/>
                  </button>
              </div>
              <h4 className="h4-text">Rate your experience</h4>
              <div className="flex justify-center gap-3 mb-4">
                  {[1, 2, 3, 4, 5].map((star) => (
                      <button
                          key={star}
                          onClick={() => handleRatingClick(star)}
                          className="my-6"
                      >
                          {/* &#9733; */}
                          { rating >= star ? <YellowStartIcon/> : <GrayStarIcon/>}
                      </button>
                  ))}
              </div>
              <textarea
                  className="w-full body-text h-[8rem] px-4 resize-none py-4 border rounded mb-4"
                  placeholder="If you have any feedback, please type it in here"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
              ></textarea>
              <p className='text-[red] text-sm'>{error}</p>
              <div className='flex justify-end '>
              <button
                  onClick={handleSubmit}
                  className=" greenbg px-3 body-text py-2 text-white rounded-lg "
              >
                  Submit Feedback
              </button>
              </div>
          </div>
      </div>
  );
};

export default FeedbackModal