import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Checkbox from "../../common/Checkbox";
import RegisterTermsConditions from "./RegisterTermsConditions";
import { commenInputDivSpace, formStyle, termAndConditionStyle } from "./RegisterStyle";
import { AuthButtonClass, AuthButtonDisabledClass, InputFieldClass, LabelFieldClass } from "../../common/StyleCommon";
import InputField from "../../common/InputField";
import Button from "../../common/Button";
import { validateCompanyRegisterForm } from "../../common/Validations";
import { emailRegex, Axios, apiHost } from "../../common/Config";
import usePost from "../../Hook/usePost";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css' // Add this line to import the necessary CSS

const GuestRegister = () => {
  const navigate = useNavigate();
  const { data: emailData, error: emailError, postData: postEmailData } = usePost('guests/verify-email');
  const { data: mobileData, error: mobileError, postData: postMobileData } = usePost('guests/verify-phone');

  const [formData, setFormData] = useState({
    fullName: "",
    companyEmail: "",
    mobileNumber: "",
    termsAccepted: false,
    usertype:'',
    signUpStatus: "company"
  });

  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [previousDialCode, setPreviousDialCode] = useState('+91');

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debouncedPostEmailData = debounce(postEmailData, 300);
  const debouncedPostMobileData = debounce(postMobileData, 300);

  const handleChange = (e) => {
    let { name, value, type, checked } = e.target;

    if (name === 'companyEmail' && !emailRegex.test(value)) {
      setErrors({ ...errors, 'companyEmail': "Enter valid company email id" });
    } else {
      let newError = { ...errors };
      if (('companyEmail' in newError) && Object.keys(newError).length === 1) {
        delete newError.companyEmail;
      }
      if (name in newError) {
        delete newError[name];
      }
      setErrors(newError);
    }

    if (name === 'companyEmail') {
      debouncedPostEmailData({ email: value });
    } 
    if (name === 'mobileNumber' && value?.length > 9) {
      debouncedPostMobileData({ phone: value?.replaceAll(" ", "").slice(-10) });
    }
    if (name === 'mobileNumber') {
      value = value?.replace(/\D/g, '');
    }

    const updatedFormData = {
      ...formData,
      [name]: type === "checkbox" ? checked : value
    };

    setFormData(updatedFormData);

    if (errors[name] && Object.keys(errors).length >= 5) {
      validateCompanyRegisterForm(updatedFormData, setErrors);
    }
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateCompanyRegisterForm({ ...formData, mobileNumber: formData?.mobileNumber?.replaceAll(" ", "").slice(-10) }, setErrors)) {
      if (mobileData?.status === true) {
        setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Mobile number already exists" }));
      }
      else if (emailData?.status === true) {
        setErrors((prevErrors) => ({ ...prevErrors, email: "Email already exists" }));
      }
      else{
        sessionStorage.setItem("guestData", JSON.stringify({ ...formData, mobileNumber: formData?.mobileNumber?.replaceAll(" ", "").slice(-10) }));
      
        navigate("/set-password");
      }
    }
  };

  useEffect(() => {
    if (emailData?.status === true) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "Email already exists" }));
    }
  }, [emailData, emailError]);

  useEffect(() => {
    if (mobileData?.status === true) {
      setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Mobile number already exists" }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "" }));
    }
  }, [mobileData, mobileError]);


  useEffect(() => {
    if (formData?.termsAccepted) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData]);
  return (
    <div>
      <div className={formStyle}>
        <form onSubmit={handleSubmit}>
          <div>
            <div className={commenInputDivSpace}>
              <InputField
                disabled={false}
                astrix={true}
                label="Full Name"
                type="text"
                id="fullName"
                name="fullName"
                required={true}
                value={formData.fullName}
                onChange={handleChange}
                error={errors.fullName}
                labelClass={LabelFieldClass}
                className={InputFieldClass}
              />
            </div>
          </div>
          <div>
            <div className={commenInputDivSpace}>
              <InputField
                disabled={false}
                astrix={true}
                label="Email ID"
                type="email"
                id="companyEmail"
                name="companyEmail"
                required={true}
                value={formData.companyEmail}
                onChange={handleChange}
                error={errors?.email}
                labelClass={LabelFieldClass}
                className={InputFieldClass}
              />
            </div>
          </div>
          <div>
            <div className={commenInputDivSpace}>
              <PhoneInput
                country={'in'}
                disableCountryGuess={true}
                countryCodeEditable={false}
                value={formData.mobileNumber}
                onChange={(fullPhoneNumber, country) => {
                  const newDialCode = `+${country.dialCode}`;
                  if (newDialCode !== previousDialCode) {
                    const newMobileNumber = newDialCode;
                    setFormData({ ...formData, mobileNumber: newMobileNumber });
                    setPreviousDialCode(newDialCode);
                    handleChange({ target: { name: 'mobileNumber', value: newMobileNumber } });
                  } else {
                    setFormData({ ...formData, mobileNumber: fullPhoneNumber });
                    handleChange({ target: { name: 'mobileNumber', value: fullPhoneNumber } });
                  }
                }}
                inputStyle={{ width: '100%', height: '45px' }}
                containerClass="phone-input-container"
                dropdownClass="phone-dropdown"
              />
              <p className="text-right primary-text-red text-sm">{errors && errors?.mobileNumber}</p>
            </div>
          </div>
          <div className={termAndConditionStyle}>
            <Checkbox name="termsAccepted" type="checkbox" checked={formData.termsAccepted} onChange={handleChange} />
            <RegisterTermsConditions />
          </div>
          <div className="text-start ml-8 mb-4">
            {errors.termsAccepted && <span className="primary-text-red text-sm">{errors.termsAccepted}</span>}
          </div>
          <Button type="submit" className={`${isDisabled ? AuthButtonDisabledClass : AuthButtonClass}`} disabled={isDisabled}>
            Register
          </Button>
        </form>
      </div>
    </div>
  );
};

export default GuestRegister;
