import React from 'react'

const LoaderAPI = () => {
  return (
      <div class="w-full h-full fixed top-0 left-0 bg-white opacity-100 z-[1000]">
  <div class="flex justify-center items-center mt-[50vh]">
    <div class="fas fa-circle-notch fa-spin fa-5x text-violet-600">
      Loading...
    </div>
  </div>
</div> 
  )
}

export default LoaderAPI
