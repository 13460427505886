import { toast } from "react-toastify";
import axios from "axios";

export const apiHost = "https://bapi.naspati.tech";
// export const apiHost = "https://api.bankersklub.com";
// export const apiHost = "http://127.0.0.1:8000";
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const numericRegex = /^\d+$/;    

export const ToastMessage = (type, message, options={
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    }) => {
  switch (type) {
      case "success":
          toast.success(message, options);
          break;
      case "error":
          toast.error(message, options);
          break;
      case "info":
          toast.info(message, options);
          break;
      case "warning":
          toast.warning(message, options);
          break;

      default:
          toast(message, options);
  }
}

export const Axios = (method, url, payload={}) => {
    switch (method) {
        case "GET":
            return axios.get(url);
        case "POST":
            return axios.post(url, payload);
        case "PUT":
            return axios.put(url);
        case "DELETE":
            return axios.delete(url);
        default:
            return axios.get(url);
    }
}

export const getKeyAreaServices = async(setKeyAreaServicesData) => {
    const response = await Axios("GET", `${apiHost}/master-data/service/index`);
    const data = response?.data?.data;
    setKeyAreaServicesData(data?.map((item) => item));
}

export const getCoreExpertise = async(setCoreExpertiseData) => {
    const response = await Axios("GET", `${apiHost}/master-data/core-expertise/index`);
    const data = response?.data?.data;
    setCoreExpertiseData(data?.map((item) => item));
}

export const getAvailabilityModes = async(setAvalForHire) => {
    const response = await Axios("GET", `${apiHost}/master-data/tenure/index`);
    const data = response?.data?.data;
    setAvalForHire(data);
}

export const getConsultancyModes = async(setAvalForConsultancy) => {
    const response = await Axios("GET", `${apiHost}/master-data/consultancy-mode/index`);
    const data = response?.data?.data;
    setAvalForConsultancy(data);
}

export const getServiceRegisters = async(setServiceOptions) => {
    const response = await Axios("GET", `${apiHost}/master-data/individual-services/index`);
    const data = response?.data?.data;
    setServiceOptions(data?.map((item) => item.name));
}


export const hasLetter = (str) => {
    return /[a-zA-Z]/.test(str);
}

export const hasCapitalLetter = (str) => {
    return /[A-Z]/.test(str);
}

export const hasNumberOrSpecialChar = (str) => {
    return /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(str);
}
export const formatPriceInput = (str) => {
    if (typeof str !== 'string') {
      return str;
    }
  
    // Split the input into integer and decimal parts
    let [integerPart, decimalPart] = str.split('.');
  
    // Handle the case where there is no decimal part
    if (!decimalPart) {
      decimalPart = '';
    } else {
      decimalPart = '.' + decimalPart; // Preserve the decimal point
    }
  
    // Format the integer part with commas
    if (integerPart.length > 3 && integerPart.length <= 6) {
      integerPart = `${integerPart.substr(0, integerPart.length - 3)},${integerPart.substr(integerPart.length - 3)}`;
    } else if (integerPart.length > 6 && integerPart.length <= 9) {
      integerPart = `${integerPart.substr(0, integerPart.length - 6)},${integerPart.substr(integerPart.length - 6, 3)},${integerPart.substr(integerPart.length - 3)}`;
    } else if (integerPart.length > 9) {
      integerPart = `${integerPart.substr(0, integerPart.length - 9)},${integerPart.substr(integerPart.length - 9, 3)},${integerPart.substr(integerPart.length - 6, 3)},${integerPart.substr(integerPart.length - 3)}`;
    }
  
    // Recombine the formatted integer part with the decimal part
    const formatted = integerPart + decimalPart;
  
    return formatted;
  };
  
  

export const formatDateToddmmyr = (str) => {
    if (typeof str !== 'string') {
      return str;
    }
  
    let formatted = str.split("-");
    if (formatted.length !== 3) {
      return str;
    }
  
    let result = `${formatted[2]}/${formatted[1]}/${formatted[0]}`;
    return result ?? str;
  };
  

export const handleNumericInput = (e) => {
    if (e.key === '0' || e.key === '1' || e.key === '2' || e.key === '3' || e.key === '4' ||
        e.key === '5' || e.key === '6' || e.key === '7' || e.key === '8' || e.key === '9') {
        e.preventDefault();
    }
}

export const handleNonNumericInput = (e) => {
    if (e.key !== '0' && e.key !== '1' && e.key !== '2' && e.key !== '3' && e.key !== '4' &&
        e.key !== '5' && e.key !== '6' && e.key !== '7' && e.key !== '8' && e.key !== '9' &&
        e.key !=='Backspace' && e.key !== 'Tab' && e.key !=='.') {
        e.preventDefault();
    }
}

export const handleAlphabeticInput = (e) => {
    if (!/[a-zA-Z ]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
        e.preventDefault();
    }
}

export const allowAlphabeticInputs = (e) => {
    if (hasLetter(e.key) === false) {
        e.preventDefault()
    }
}

// Function to convert date format YYYY-MM-DD to milliseconds
export const convertDateToMilliseconds = (str) => {
    let arr = str.split('-');
    let milliseconds = new Date(arr[0], arr[1] - 1, arr[2]).getTime();
    return milliseconds;
}

export const secondsDiff = (d1, d2) => {
    let millisecondDiff = d2 - d1;
    let secDiff =  millisecondDiff / 1000;
    return secDiff;
 }

export const minutesDiff = (d1, d2) => {
    let seconds = secondsDiff(d1, d2);
    let minutesDiff = seconds / 60 ;
    return minutesDiff;
}

export const hoursDiff = (d1, d2) => {
    let minutes = minutesDiff(d1, d2);
    let hoursDiff =  minutes / 60;
    return hoursDiff;
}

export const daysDiff = (d1, d2) => {
    let hours = hoursDiff(d1, d2);
    let daysDiff =  hours / 24;
    return daysDiff;
 }