import React from 'react'
import Navbar from '../../common/Navbar'
import SideNavbar from '../../common/SideNavbar'
import Referrals from '../../common/Referrals'

const ReferralsDashbaord = () => {
  return (
    <div>
        <Navbar hemBurgerIcon={true} ProfileAndNotification={true} CompanyNavbar={true} />
      
      <div className='flex'>
        <div className='lg:w-[7%] w-0 ' >
            <SideNavbar/>
        </div>
        <div className='lg:w-[93%] w-full'>
        <Referrals/>
        </div>
      </div>
    </div>
  )
}

export default ReferralsDashbaord
