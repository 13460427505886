import Navbar from "../../common/Navbar"
import ResetPassword from "../../common/ResetPassword"
import SideNavbar from "../../common/SideNavbar"


const CompanyResetPassword = () => {
  return (
    <div>
        <Navbar BackBtn={true} hemBurgerIcon={true} CompanyNavbar={true} ProfileAndNotification={true}/>

        <div className='flex'>
        <div className="lg:w-[7%] md:-[7%] w-0">
          <SideNavbar />
        </div>

        <div className='w-[100%] lg:flex  px-[1rem] md:w-[93%] lg:w-[93%] relative'>
            <ResetPassword type={false}/>
        </div>
        </div>
      
    </div>
  )
}

export default CompanyResetPassword
