import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useGetData from "../Hook/useGetData";
import { useSidebar } from "./SidebarProvider";
import { NavbarStyleBuyBtn, navbarStyleContainer, navbarStyleDiv, navbarStyleDivTwo, navbarStyleDivTwoBackBtn, NavbarStyleGetHired, NavbarStyleskipData, navbarStyleVLine } from "./StyleCommon";
import { BackButtonIcon, LogoIconSVG, MobileMenuIcon } from "./Icons";
import Button from "./Button";
import CompanyProfileOption from "../modules/dashboard/CompanyProfileOption";
import CompanyNotificationModel from "../modules/dashboard/CompanyNotificationModel";
import { LogOut } from "../Hook/validation";

const Navbar = (props) => {
  const { skipdata, buybtn, getHired , BackBtn , ProfileAndNotification , BankerNavbar ,CompanyNavbar , hemBurgerIcon} = props;
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const { data: getBankerData, loading: loadingServices, error: errorServices } = useGetData('guests/refer-details', token);
  const navigate = useNavigate();
  const location = useLocation();
  const handleDashboard = () => {
    // const userType=sessionStorage.getItem('user_type') || 1
    // if(userType==0){
      
      navigate("/dashboard/referrals");
    // }

  };
  const [profileData, setProfileData] = useState({});
  const [profileDataCompany, setProfileDataCompany] = useState({});

  const { toggleSidebar } = useSidebar(); // Use the toggleSidebar function from context



  const toggleHandle = () => {
    toggleSidebar()


  }


  return (
    <div className={navbarStyleContainer}>
      <div className={navbarStyleDiv}>
        <div className={navbarStyleDivTwo}>
         {BackBtn && <><div onClick={() => navigate(-1)} className={navbarStyleDivTwoBackBtn}>
          <BackButtonIcon/>
          <p className="hidden md:block">Back</p>

          </div>
          <div className={navbarStyleVLine}>

          </div></>}

            <div className="lg:hidden pl-4 block">
                  {hemBurgerIcon && <MobileMenuIcon onClick={toggleHandle}/> }
            </div>

          {/* <h3 className={`${navbarStyleLogo} cursor-pointer`} onClick={handleDashboard}>BankersKlub</h3> */}
          <p onClick={handleDashboard} className="pl-4 cursor-pointer">
            <LogoIconSVG/>
          </p>
        </div>
        <div className={NavbarStyleskipData}>
          {skipdata ? <p className="text-end s2-text cursor-pointer mt-4" role="button" onClick={handleDashboard}>{skipdata}</p> : ""}{" "}
          {buybtn ? (
            <div className="flex">
              <div>
                <p className={NavbarStyleGetHired}>{getHired}</p>
              </div>
            {/* {!getPaymentData?.payment_status && <div className="">
                {" "}
                <Button
                  onClick={() => navigate("/profile/banker/plan")}
                  type="button"
                  className={NavbarStyleBuyBtn}
                >
                  {buybtn}
                </Button>
              </div>} */}
              {/* <Button
            onClick={LogOut}
            type="button"
            className={`${NavbarStyleBuyBtn} m-4 flex items-center`}
          >
            Log Out
          </Button> */}
            </div>
          ) : (
            !ProfileAndNotification && token &&
            <></>
          //   <Button
          //   onClick={LogOut}
          //   type="button"
          //   className={`${NavbarStyleBuyBtn} flex items-center`}
          // >
          //   Log Out
          // </Button>
           
          )}

        {ProfileAndNotification ? <div className="flex justify-center items-center gap-5 mt-2">
          {/* <div className="text-sm " role="button" onClick={LogOut}>Logout</div> */}
            <div>
              {/* <NotificationBellIcon />  */}

              {/* <Notification/> */}
             {/* { CompanyNavbar &&  <CompanyNotificationModel />} */}

            </div>
            { getBankerData?.name && <CompanyProfileOption getBankerData={getBankerData} onClick={LogOut} data={getBankerData?.name}/> } 

        </div> : ""}

        </div>

        
        

        
      </div>
      
      
    </div>
  );
};
 
export default Navbar;
