import { useNavigate } from "react-router-dom";
import { RegisterStyleContainer, RegisterStyleContainerDiv2, RegisterStyleContainerDivSpan, RegisterStyleContainerHanldle, RegisterStyleContainerHanldleBanker, RegisterStyleMainContainer, logoFontStyle } from "./RegisterStyle";
import GuestRegister from "./GuestRegister";
 
const Register = () => {

  const navigate = useNavigate();
  return (
    <div className={RegisterStyleMainContainer}>
      <div className={RegisterStyleContainer}>
      <div><h3 className={logoFontStyle}>BankersKlub</h3></div>

        <div className={RegisterStyleContainerDiv2}>
          <p className="lg:ml-auto flex justify-end">
            Already have an account ?{" "}
            
          </p>
          <span
              onClick={() => navigate("/login")}
              className={RegisterStyleContainerDivSpan}
            >
              {" "}
              Log in
            </span>
        </div>
      </div>
 
      <div className={RegisterStyleMainContainer}>
        <h2 className="h2-text py-3">
 
          Register Now
        </h2>
      </div>
 
     
 
      <GuestRegister /> 
    </div>
  );
};
 
export default Register;