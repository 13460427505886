import React, { useEffect, useState } from 'react';
import useGetData from '../../Hook/useGetData';
import { ToastMessage } from '../../common/Config';
import LoaderAPI from '../../common/LoaderAPI';
import Navbar from '../../common/Navbar';
import SideNavbar from '../../common/SideNavbar';
import UploadProfileModal from './UploadProfileModal';
import InputField from '../../common/InputField';
import { PencilIcon } from '../../common/Icons';
import Button from '../../common/Button';
import SelectTagComponent from '../../common/SelectTagComponent';
import usePost from '../../Hook/usePost';



const MyProfile = () => {
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const { data, error, loading, refetch:fetchAgain } = useGetData("profile/company/basic-details", token);
  const { data: getStateData, loading: loadingState, error: errorState } = useGetData('master-data/state/get',"token");
  const { data: updatedData, error: updateError, loading: updateLoading, postData: updateBankerDetails } = usePost("profile/company/update-details");
  const { data: getTurnOverData } = useGetData('master-data/turnovers/index',"token");
  const { data: getVintageData } = useGetData('master-data/vintages/index',"token");
  const [EditNameIcon, SetEditNameIcon] = useState(true);
  const [EditBasicIcon, SetEditBasicIcon] = useState(true);
  const [profilePhoto, setProfilePhoto] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handlePencilClick = () => {
    setIsModalOpen(true);
  };

  const handleImageSelect = (image) => {
    setImageData(image);
  };
  
  const CompanyPro = data?.data?.company ? true :false;
  const [UserName, setUserName] = useState('');
  const [basicDetailCompany, setBasicDetailCompany] = useState([
    { name: "Email Id", value: "example@gmail.com",error:"" },
    { name: "Mobile No.", value: "+91 9898989898",error:"" },

  ]);



  const [stateId,setStateId] = useState(data?.data?.company?.state?.id || 0);
  const [Loader,setLoader] = useState(false);
  const { data: getCityData, loading: loadingCity, error: errorCity } = useGetData(`master-data/city/${stateId}`,"token");

  

  const handleBasicDetails=() => {
    

    const CityId=typeof basicDetailCompany?.filter((item)=>item?.name==="City")[0]?.value==='number' ?      basicDetailCompany?.filter((item)=>item?.name==="City")[0]?.value : getCityData?.data?.filter((item)=>item.city===basicDetailCompany?.filter((item)=>item?.name==="City")[0]?.value)[0]?.id || 0

    const StateId=typeof basicDetailCompany?.filter((item)=>item?.name==="State")[0]?.value==='number' ?basicDetailCompany?.filter((item)=>item?.name==="State")[0]?.value : getStateData?.data?.filter((item)=>item.name===basicDetailCompany?.filter((item)=>item?.name==="State")[0]?.value)[0]?.id || 0

    const TurnOverId=typeof basicDetailCompany?.filter((item)=>item?.name==="Turnover Grid")[0]?.value==='number' ?basicDetailCompany?.filter((item)=>item?.name==="Turnover Grid")[0]?.value : getTurnOverData?.data?.filter((item)=>item.name===basicDetailCompany?.filter((item)=>item?.name==="Turnover Grid")[0]?.value)[0]?.id || 0

    const VintageId=typeof basicDetailCompany?.filter((item)=>item?.name==="Vintage Grid")[0]?.value==='number' ?basicDetailCompany?.filter((item)=>item?.name==="Vintage Grid")[0]?.value : getVintageData?.data?.filter((item)=>item.name===basicDetailCompany?.filter((item)=>item?.name==="Vintage Grid")[0]?.value)[0]?.id || 0
    const obj={
      "turnover":TurnOverId,
      "vintage":VintageId,
      "city": CityId,
      "state": StateId,
      "company_name":basicDetailCompany?.filter((item)=>item?.name==="Company Name")[0]?.value,
      // "bk_user": {
      //     "name": "John D"
      // }
  }
  if(obj?.city!==0){
    setLoader(true)
   updateBankerDetails(obj, token);
   SetEditBasicIcon(!EditBasicIcon);
  }else{
    const updatedBasicDetailCompany = [...basicDetailCompany];
    updatedBasicDetailCompany[4].error = "Please select city";
    
    // Update the state with the modified array
    setBasicDetailCompany(updatedBasicDetailCompany);
  }
 
    
    }


    const handleNameSave=()=>{
      const obj={
        // "turnover":TurnOverId,
        // "vintage":VintageId,
        // "city": CityId,
        // "state": StateId,
        // "company_name":basicDetailCompany?.filter((item)=>item?.name==="Company Name")[0]?.value,
        "bk_user": {
            "name": UserName
        }
    }
      updateBankerDetails(obj, token);
      SetEditNameIcon(!EditNameIcon)
    }

  useEffect(() => {
    const companyData = data?.data?.company;
    const individualData = data?.data?.individual;
    let companyDetails = [...basicDetailCompany];
if(companyData){
  setUserName(companyData?.user?.name)
}
    for (let i = 0; i < companyDetails?.length; ++i) {
      if (companyDetails[i]["name"] === "Company Name") {
        companyDetails[i]["value"] = companyData?.company_name ?? "Not available";
      }
      if (companyDetails[i]["name"] === "Company Email ID") {
        companyDetails[i]["value"] = companyData?.user?.email ? companyData?.user?.email : individualData?.user?.email ? individualData?.user?.email : "Not available";
      }
      if (companyDetails[i]["name"] === "Phone Number") {
        companyDetails[i]["value"] = companyData?.user?.phone_number ? `+91-${companyData?.user?.phone_number}` : individualData?.user?.phone_number ? `+91-${individualData?.user?.phone_number}` : "Not available";
      }
      if (companyDetails[i]["name"] === "State") {
        companyDetails[i]["value"] = companyData?.state?.name ? companyData?.state?.name : individualData?.state?.name ? individualData?.state?.name : "Not available";
        setStateId( companyData?.state?.id)
      }
      if (companyDetails[i]["name"] === "City") {
        companyDetails[i]["value"] = companyData?.city?.city ? companyData?.city?.city : individualData?.city?.city ? individualData?.city?.city : "Not available";
      }
      if (companyDetails[i]["name"] === "Turnover Grid") {
        companyDetails[i]["value"] = companyData?.turnover?.name ?? "Not available";
      }
      if (companyDetails[i]["name"] === "Vintage Grid") {
        companyDetails[i]["value"] = companyData?.vintage?.name ?? "Not available";
      }
    }
    setBasicDetailCompany(companyDetails);
  }, [data?.data?.company]);




  const handleInputChange = (index, newValue) => {
    const updatedDetails = [...basicDetailCompany];
    if(updatedDetails[index]?.name==="State"){
      setStateId(newValue);
      let updatedBasicDetailCompany = [...basicDetailCompany];
      updatedDetails[index].value = newValue;
      updatedBasicDetailCompany[4].error = "Please select city";
      updatedBasicDetailCompany[4].value = "select city";
      
      // Update the state with the modified array
      setBasicDetailCompany(updatedBasicDetailCompany);
    }else if(updatedDetails[index]?.name==="City"){
      const updatedBasicDetailCompany = [...basicDetailCompany];
      updatedBasicDetailCompany[4].error = "";
      updatedBasicDetailCompany[4].value = newValue;
        // Update the state with the modified array
        setBasicDetailCompany(updatedBasicDetailCompany);
      // Update the state with the modified array
    }else{
    updatedDetails[index].value = newValue;
    setBasicDetailCompany(updatedDetails);
    }


  };


  const handleInputNameChange = (newValue) => {
    setUserName(newValue);
  };
  useEffect(()=>{
    if(updatedData){
      ToastMessage("success","Profile Updated Successfully")
    }
    if(updateError){
      ToastMessage("error","Something went wrong")
    
    }
    fetchAgain()
setLoader(false)
  },[updatedData,updateError])

  return (
    <div>
      {(Loader || loading)&& <LoaderAPI />}

      <Navbar hemBurgerIcon={true} ProfileAndNotification={true} CompanyNavbar={true} />

      <div className='flex'>
        <div className='w-0 md:w-[7%]'>
          <SideNavbar />
        </div>
        <div className='w-full lg:w-[93%] px-2 md:px-8'>
          <div className='sticky-profile'>
            <h4 className='h4-text pt-4 text-start text-[#2F3340]'>My Profile</h4>
          </div>
          <div className={`border relative w-full my-8 p-4 grid grid-cols-1 md:grid md:grid-cols-2 rounded-lg ${!EditBasicIcon ? "opacity-[30%]" : ""}`}>
            <div className='flex items-center gap-4'>
    
<div className='relative'>

        <UploadProfileModal EditNameIcon={!EditNameIcon} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto}  onClose={() => setIsModalOpen(false)} onImageSelect={handleImageSelect} />


  
    </div>
              <div className='w-[80%] text-start'>
                {EditNameIcon && <h5 className='h5-text'>{data?.data?.company?.user?.name || data?.data?.individual?.user?.name || "Not Available"}</h5>}
                {!EditNameIcon && 
                <InputField
                 onChange={(e) => handleInputNameChange( e.target.value)}
                  value={UserName}
                 />}
              </div> 
            </div>
            <div className='flex justify-end items-start gap-4'>
              {EditNameIcon && <PencilIcon onClick={() => SetEditNameIcon(!EditNameIcon)} />}
              {!EditNameIcon && <Button onClick={handleNameSave} className="redfolicbg py-2 px-5 rounded-lg ">
                Save Changes
              </Button>}
            </div>
          </div>

          <div className={`border w-full my-8 p-4 rounded-lg ${!EditNameIcon ? "opacity-[30%]" : ""}`}>
            <div className='grid grid-cols-2'>
              <div className='flex items-center gap-4'>
                <h6 className='h6-text'>Basic Details</h6>
              </div>
              <div className='flex justify-end items-start gap-4'>
                {EditBasicIcon && <PencilIcon onClick={() => SetEditBasicIcon(!EditBasicIcon)} />}
                {!EditBasicIcon && <Button
                onClick={handleBasicDetails}
                  className="redfolicbg py-2 px-5 rounded-lg ">
                  Save Changes
                </Button>}
              </div>
            </div>

            {CompanyPro && <div className={`grid grid-cols-1 md:grid md:grid-cols-2 mb-4 w-[90%] mt-6 ${!EditBasicIcon ? "gap-10" : "gap-7"}  `}>
              {basicDetailCompany?.map((item, index) => (
                 <div className='grid relative grid-cols-2 text-start h-[40px]'>
                 <div className='flex justify-between items-center'>
                   <p className='body-text  text-[#80828D]'>{item?.name}</p>
                   <div className='mr-[40%]'>-</div>
                 </div>
                <div className='absolute w-[65%] top-1 left-[35%] text-start flex justify-start items-center'>
                 
                  {/* <div className='absolute top-1 left-[35%] text-start flex justify-start items-center'> */}
                    {EditBasicIcon && <p className='notification-span mt-2 text-[#2C2C2C]'>{item?.value}</p>}
                    {!EditBasicIcon && <div className='-mt-6 w-full'>
                      {item.name === "Vintage Grid" || item.name === "Turnover Grid" || item.name === "State" || item.name === "City" ? (
                        <div className='mt-2 w-full lg:w-[16rem]'><SelectTagComponent defaultVal={item?.value} options={item?.name==="State" ? getStateData?.data :item?.name==='City' 
                        ? getCityData?.data?.map((item)=>{return {...item,name:item?.city}}):
                        item?.name==='Turnover Grid' ? getTurnOverData?.data:item?.name==='Vintage Grid' ? getVintageData?.data:
                        []} onChange={(e) => handleInputChange(index, e?.id)} />
                
                        </div>
                      ) : (
                        <div className= 'w-full lg:w-[16rem]'>
                        <InputField disabled={(item?.name=='Company Email ID' || item?.name=='Phone Number')} value={item?.value || ''} onChange={(e) => handleInputChange(index, e.target.value)} />

                        </div>
                      )}
                  <p className='text-[red]'>{item?.error}</p>

                    </div>}

                  </div>

                </div>
                
              ))}
            </div>}

           
          </div>


        </div>
      </div>
    </div>
  );
};

export default MyProfile;
