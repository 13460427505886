import React, { useState, useEffect } from 'react';
import useGetData from '../Hook/useGetData';
import { FaceBookIcon, IButtonIcon, InstagramIcon, LinkedInIcon, WhatsAppIcon } from './Icons';
import InputField from './InputField';
import Button from './Button';
// import { FaceBookIcon, IButtonIcon, InstagramIcon, LinkedInIcon, WhatsAppIcon } from './Icons';
// import InputField from './InputField';
// import { BP_MainDiv5, BP_MainDiv6 } from '../modules/dashboard/banker/project/BankerProjectStyle';
// import { JobListingCountStyle, JobListingMenuBarDivAvticeTab } from '../modules/dashboard/company/job/DashboardJobStyle';
// import useGetData from '../Hook/useGetData';
// // import usePost from '../Hook/usePost';
// import Button from './Button';

const Referrals = () => {
  const [inputValue, setInputValue] = useState('');
  const [activeTab, setActiveTab] = useState('All');
  const [list, setList] = useState([]);
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: getReferData, loading: loadingServices, error: errorServices } = useGetData('guests/refer-details',token);
  const { data: getReferLinkData } = useGetData('guests/referral',token);



  // const getProjectList = {
  //   data: [
  //     { id: 1, userName: 'Ravi', status: 'Verified' },
  //     { id: 2, userName: 'Nitish', status: 'Payment Done' },
  //     { id: 3, userName: 'Ravi', status: 'Payment Done' },
  //     { id: 4, userName: 'Nitish', status: 'Verified' }
  //     // Add other projects here
  //   ],
  // };

  useEffect(() => {
    setList(getReferData?.referred_users);
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab.name);
    if (tab.id === 1) {
      setList(getReferData?.referred_users);
    } else {
      setList(getReferData?.referred_users.filter((item) => item.status === tab.name));
    }
  };

  const mainTag = [
    { id: 1, name: 'All', length: getReferData?.referred_users?.length || 0 },
    { id: 2, name: 'Verified', length: getReferData?.referred_users?.filter((item) => item?.referral_verified === true).length || 0},
    { id: 3, name: 'Payment Done', length: getReferData?.referred_users?.filter((item) => item?.payment_done === true).length || 0 },
  ];

  const handleCopy = (val) => {
    navigator.clipboard.writeText(val)
      .then(() => {
        alert('Copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className='w-full py-4 px-3 md:p-6 lg:p-6'>
      <div className='w-full border px-4  md:px-6 lg:px-6 py-4 rounded-lg'>
        <h6 className='h6-text mb-4'>Your Rewards</h6>
        <div className='grid grid-cols-1 md:grid lg:grid md:grid-cols-2 lg:grid-cols-2'>
          <div>
            <h4 className='h4-text text-start text-[#1EB469]'>{getReferData?.pending_credits} credits</h4>
            <p className='body-text gap-2 flex justify-start items-center text-[#2C2C2C]'>
              Your pending rewards <IButtonIcon />
            </p>
          </div>
          <div className='md:pl-4 lg:pl-4 mt-4 md:mt-0 lg:mt-0 text-start' >
            <h4 className='h4-text text-start text-[#2C2C2C]'>{getReferData?.total_credits} credits</h4>
            <div className='flex justify-start gap-2 items-center'>
            <p className='body-text gap-2 flex   text-[#2C2C2C]'>
              Your total amount you have earned by referring people to BankersKlub 
            </p>
            <div>
            <IButtonIcon />
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 md:grid lg:grid md:grid-cols-2 lg:grid-cols-2 my-4 gap-4'>
        {/* {['Refer a Company', 'Refer a Banker'].map((item, index) => (
          <div key={index} className='px-4 lg:px-6 md:px-6  py-4 border rounded-lg'>
            <h6 className='h6-text pb-2 text-[#2C2C2C]'>{item}</h6>
            <InputField
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue || 'https://hostinger.in?REFERRALCODE=1RADHIKA06'}
              onClickCopy={handleCopy}
              CopyIconValue={true}
            />
            <div className='flex justify-end gap-4 mt-4'>
              <FaceBookIcon />
              <InstagramIcon />
              <WhatsAppIcon />
              <LinkedInIcon />
            </div>
          </div>
        ))} */}

<div className='px-4 lg:px-6 md:px-6  py-4 border rounded-lg'>
            <h6 className='h6-text pb-2 text-[#2C2C2C]'>Refer a Company</h6>
            <InputField
              onChange={(e) => setInputValue(e.target.value)}
              value={getReferLinkData?.company_referral_link || ''}
              onClickCopy={(e)=>handleCopy(getReferLinkData?.company_referral_link || '')}
              CopyIconValue={true}
            />
            <div className='flex justify-end gap-4 mt-4'>
              <a href="https://www.facebook.com/people/BankersKlub/61559630877212/" target='_blank'>
              <FaceBookIcon />
              </a>
              <a href="https://www.instagram.com/bankersklubofficial/" target='_blank'>
              <InstagramIcon />
              </a>
              <a href="https://wa.me/917042721408" target='_blank'>
              <WhatsAppIcon />

                </a>
                <a href="https://www.linkedin.com/company/bankersklub/" target='_blank'>
                <LinkedInIcon />
                </a>
            
            </div>
          </div>
          <div className='px-4 lg:px-6 md:px-6  py-4 border rounded-lg'>
            <h6 className='h6-text pb-2 text-[#2C2C2C]'>Refer a Banker</h6>
            <InputField
              onChange={(e) => setInputValue(e.target.value)}
              value={getReferLinkData?.banker_referral_link || 'https://hostinger.in?REFERRALCODE=1RADHIKA06'}
              onClickCopy={(e)=>handleCopy(getReferLinkData?.banker_referral_link || 'https://hostinger.in?REFERRALCODE=1RADHIKA06')}
              CopyIconValue={true}
            />
            <div className='flex justify-end gap-4 mt-4'>
            <a href="https://www.facebook.com/people/BankersKlub/61559630877212/" target='_blank'>
              <FaceBookIcon />
              </a>
              <a href="https://www.instagram.com/bankersklubofficial/" target='_blank'>
              <InstagramIcon />
              </a>
              <a href="https://wa.me/917042721408" target='_blank'>
              <WhatsAppIcon />

                </a>
                <a href="https://www.linkedin.com/company/bankersklub/" target='_blank'>
                <LinkedInIcon />
                </a>
            
            </div>
          </div>
      </div>

      <div className='grid grid-cols-1 md:grid lg:grid md:grid-cols-2 lg:grid-cols-2 my-4 gap-4'>
        <div className='px-6 rounded-lg border'>
          <div className='flex w-full justify-between lg:justify-start lg:gap-8 py-6'>
            {mainTag?.map((item) => (
              <div key={item.id}>
                <div
                  className={`flex justify-between  text-[14px] gap-1 cursor-pointer ${activeTab === item?.name ? 'font-bold' : ''}`}
                  onClick={() => handleTabClick(item)}
                >
                  <div>{item.name}</div>
                  <div className='w-[1.5rem] h-[1.5rem] rounded-full bg-[#D8D9E0]'>{item?.length}</div>
                </div>
                <div className={`h-1 mt-4 border-1 ${activeTab === item.name ? 'greenbg' : 'bg-transparent'}`}></div>
              </div>
            ))}
          </div>
          <div className='w-full border -mt-6 mb-4' ></div>
          <div className="w-full my-4">
            <div className="grid grid-cols-2 ">
              <div className='text-start'>
                <p className='s3-text text-[#808080]'>User Name</p>
              </div>
              <div className='text-end text-[#808080]'>
                <p className="s3-text ">Status</p>
              </div>
            </div>
            
          </div>
          <div className='w-full border  mb-4' ></div>

          {list?.map((item ,index) => (
           <div className="flex items-center justify-between my-4">
           <div className='text-start'>
             <p className='s3-text text-[#808080]'>{item?.name}</p>
           </div>
           <div className='text-end text-[#808080]'>
             <p className={`s3-text ${item?.referral_verified === true ? "rounded-[24.5px] px-4 py-2 bg-[#EFF0F3] max-w-max" : "rounded-[24.5px] px-4 py-2 bg-[#D5F2DD] max-w-max" }`}>{item?.referral_verified === true ?'' : item?.referral_verified === true }</p>
           </div>
         </div>
          ))}
        </div>

        <div className='bg-[#F9F9FB] p-6 text-start rounded-lg'>
          <h6 className='h6-text mb-4'>How it Works</h6>
          <ol className='list-decimal ml-5'>
            <li className='mb-2 '>
              <strong>New users only:</strong> The person you’re sharing the link to cannot have any existing account at Hostinger.
            </li>
            <li className='mb-2'>
              <strong>Hosting purchases only:</strong> Rewards are only eligible for first-time purchases of web hosting or VPS plans for 12 months or longer. Purchases of domains, emails, and cPanel hosting do not qualify.
            </li>
            <li className='mb-2'>
              <strong>No refunds and active for 45 days:</strong> The referred client does not refund and is active for at least 45 days.
            </li>
          </ol>
          <p className='mt-4'>
            Earnings will be transferred via PayPal or Wire transfer depending on the payment method you have chosen on the referral dashboard:
          </p>
          <ul className='list-disc ml-5'>
            <li>Earnings will be issued after collecting 50 USD of earnings for PayPal</li>
            <li>Earnings will be issued after collecting 100 USD of earnings for Wire transfer</li>
          </ul>
          <a href='https://bankersklub.com/terms-and-conditions/' target="_blank">
          <Button className='redfolicbg1 rounded-md mt-4 px-2 py-1'>
        Term of Services
        </Button>

          </a>
        
        </div>

        
      </div>

      {/* <div className='h-[5rem] lg:hidden md:hidden block'>

      </div> */}
    </div>
  );
};

export default Referrals;
