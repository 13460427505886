import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../common/Navbar";
import { FogotButtonStyle, ForgotContainer, ForgotHeadingTwo, ForgotInputField, ForgotmainContainer } from "../register/RegisterStyle";
import Button from "../../common/Button";
import InputField from "../../common/InputField";
import { ForgotIcon } from "../../common/Icons";
import usePost from "../../Hook/usePost";
import { ToastMessage } from "../../common/Config";
 
 
const Forgot = () => {
  const { data, error, loading, postData } = usePost('onboarding/send-otp'); 
  const [emailMobile,setEmailMobile]=useState("")
  const [errors,setErrors]=useState("")
  const navigate = useNavigate();
  
  const handleEmailMobile=(e)=>{
    const value = e.target.value;
    setEmailMobile(value);
    setErrors("");

    // if (!value) {
    //   setErrors("This field is required");
    // } else if (!/^\d{10}$/.test(value) && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    //   setErrors("Enter a valid email or 10-digit mobile number");
    // }
  }

  const handleSubmit = () => {
  
    // Validate the email/mobile number input before submission
    if (!emailMobile) {
      setErrors("This field is required");
    } else if (!/^\d{10}$/.test(emailMobile) && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailMobile)) {
      setErrors("Enter a valid email or 10-digit mobile number");
    } else {
      setErrors(""); // Clear any errors
      postData({ identifier: emailMobile });
    }
  };
  
  
  useEffect(()=>{
    if(data?.status==200){
      sessionStorage.setItem("forgotData",JSON.stringify(data))
      navigate("/forgot-otp")
    }
    if(error?.message){
      ToastMessage('error',error?.message)
    }
  },[data,error])

  return (
    <div>
      <Navbar />
      <div className="mt-[7rem]">
        <div>
          <div className={ForgotmainContainer}>
            <div className={ForgotContainer}>
              <ForgotIcon/>
            </div>
            <h3 className={ForgotHeadingTwo}>
              Forgot Password?
            </h3>
            <p className="">Enter your registered email ID or Mobile number.</p>
            <div>
              <div className={ForgotInputField}>
                <InputField label={"Email/Mobile number"} id="emailMob" onChange={handleEmailMobile} placeholder={"Enter your email/mobile number"} type="text" error={errors} />
              </div>
            </div>
            <Button
              onClick={handleSubmit}
              className={FogotButtonStyle}
              disabled={loading}
            >
              {loading ? "..." : "Continue"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Forgot;
