
import usePost from "../../Hook/usePost";
import { ForgotOTPIcon } from "../../common/Icons";
import Navbar from "../../common/Navbar";
import OtpBox from "../../common/OtpBox";
import {ForgotContainer, ForgotOTPmainContainer, ForgotOTPmainContainerh3, ForgotmainContainerOTP} from "../register/RegisterStyle"
 
const ForgotOTP = () => {

  const ForgotData=sessionStorage.getItem("forgotData");
  const ForgotDataJSON=JSON.parse(ForgotData);
   return (
    <div>
      <Navbar />
      <div className={ForgotmainContainerOTP}>
        <div>
          <div className={ForgotOTPmainContainer}>
            <div className={ForgotContainer}>
             <ForgotOTPIcon/>
            </div>
            <h3 className={ForgotOTPmainContainerh3}>
                Forgot Password?
            </h3>
            <p className="m-auto s3-text my-[2rem] ">
              We have sent you an OTP on your email{" "}
              <span className="primary-text-green">{ForgotDataJSON?.email ||"ba***r***@e***ple.com"}</span>{" "}
              and Mobile no.
              <span className="primary-text-green">{ForgotDataJSON?.phone_number || "98******34"}</span>
            </p>
            <div>
              <OtpBox ForgotData={ForgotDataJSON} forgotPassword={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default ForgotOTP;