import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../common/Navbar";
import {
  ForgotSetPasswordmainContainer,
  ForgotSetPasswordmainContainerDiv,
  ForgotSetPasswordmainContainerheading,
  SetPasswordStyleInputDiv,
  SetPasswordStyleListSVG,
  SetPasswordStyleResetButton,
  SetPasswordStyleResetDiv,
  SetPasswordStyleSVG,
  SetPasswordStyleSVG2,
  SetPasswordStyleUL,
} from "../register/RegisterStyle";
import { InputFieldClass, flexitemCentre, setPassButtonClass } from "../../common/StyleCommon";
import InputField from "../../common/InputField";
import Button from "../../common/Button";
import {
  CloseEyePasswordIcon,
  LockIcon,
  OpenEyePasswordIcon,
  ResetPasswordRightTick,
} from "../../common/Icons";
import usePost from "../../Hook/usePost";
import {
  ToastMessage,
  hasCapitalLetter,
  hasLetter,
  hasNumberOrSpecialChar,
} from "../../common/Config";
import { Loader } from "../../common/Loader";

const SetPassword = ({ isBankerSignup = false }) => {
  const navigate = useNavigate();
  const ForgotData=sessionStorage.getItem("forgotData");
  const ForgotDataObject=JSON.parse(ForgotData);
  const postUrl=ForgotDataObject ? "guest/forgot-password" :"guests/sign-up"

  const { data, error, loading, postData } = usePost(postUrl);

  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [passwordLength, setPasswordLength] = useState(0);
  const [showPassword, setShowPassword] = useState(true);
  const [showRePassword, setShowRePassword] = useState(true);
  const [errors, setErrors] = useState({});
  
  const handleInputChange = (e) => {
    const newPassword = e.target.value;
    let errs = {...errors};

    if ("password" in errs)  delete errs["password"]
    
    setErrors(errs);
    setPassword(newPassword);
    setPasswordLength(newPassword.length);
  };

  const handleRePasswordChange = (e) => {
    let errs = {...errors};

    if ("rePassword" in errs)  delete errs["rePassword"];

    setErrors(errs);
    setRePassword(e.target.value);
  };

  // const hasLetter = /[a-zA-Z]/.test(password);
  // const hasNumberOrSpecialChar = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
  const isContinueDisabled =
    password === "" ||
    rePassword === "" ||
    password !== rePassword ||
    !hasCapitalLetter(password) ||
    !hasLetter(password) ||
    !hasNumberOrSpecialChar(password) ||
    passwordLength < 8;

  const handleConfirm = () => {
    const DataGuest = JSON.parse(sessionStorage.getItem("guestData"));
 

    let userObject;

    let errs = {}

    if(!password) {
      errs['password'] = "This field is required";
    }

    if(!rePassword) {
      errs['rePassword'] = "This field is required";
    }

    if(password && rePassword && isContinueDisabled && password !== rePassword) {
      // errs['password'] = " Entered password should be same";
      errs['rePassword'] = " Entered password should be same";
    }

    if(Object.keys(errs).length > 0) {
      setErrors(errs);
      return;
    }

    if (isContinueDisabled) {
      return;
    }


  
    postData({
      name:DataGuest?.fullName || "" ,
      email: DataGuest?.companyEmail || "test@guest.com",
      phone_number:DataGuest?.mobileNumber || "9876543210",
      password: password
    });
  };

  useEffect(() => {
    if (data?.status === "success") {
      ToastMessage("success", "Password set successfully");
      sessionStorage.removeItem("bankerData");
      sessionStorage.removeItem("companyData");
      sessionStorage.removeItem("individualsData");
      navigate("/congratulation");
    } else if (
      error?.response?.data?.email &&
      error?.response?.data?.phone_number
    ) {
      ToastMessage(
        "error",
        "Given email and mobile number are already registered"
      );
    }
    if(data?.status==200 || data?.status==201 ){
      // ToastMessage("success", "Password set successfully");
      sessionStorage.removeItem("forgotData");
      navigate(ForgotDataObject ? "/done" : "/congratulation");
      // navigate("/congratulation");
    }
    if(error){
      ToastMessage("error", error?.response?.data?.error);
    }
  }, [data, error]);


  return (
    <div>
      <Navbar />

      <div>
        <div>
          {loading ? (
            <Loader />
          ) : (
            <div className={ForgotSetPasswordmainContainer}>
              <div className={ForgotSetPasswordmainContainerDiv}>
                <LockIcon />
              </div>
              <h3 className={ForgotSetPasswordmainContainerheading}>
                Please set your password
              </h3>
              <div>
                <div className={SetPasswordStyleInputDiv}>
                  <InputField
                    label="New password"
                    astrix={true}
                    id="newPassword"
                    disabled={false}
                    required={true}
                    type={!showPassword ? "text" : "password"}
                    value={password}
                    name=""
                    onChange={handleInputChange}
                    className={InputFieldClass}
                    error={errors.password}
                  />
                  {showPassword ? (
                    <CloseEyePasswordIcon
                      onClick={() => setShowPassword(!showPassword)}
                      className={SetPasswordStyleSVG}
                    />
                  ) : (
                    <OpenEyePasswordIcon
                      onClick={() => setShowPassword(!showPassword)}
                      className={SetPasswordStyleSVG}
                    />
                  )}
                </div>
              </div>
              <ul className={SetPasswordStyleUL}>
                <li className={flexitemCentre}>
                  <ResetPasswordRightTick
                    className={`${SetPasswordStyleListSVG} ${passwordLength >= 8 ? "primary-text-green" : "text-[#D3D3D3]" } flex-shrink-0`}
                  />
                  At least 8 characters
                </li>
                <li className={flexitemCentre}>
                  <ResetPasswordRightTick
                    className={`${SetPasswordStyleListSVG} ${hasCapitalLetter(password) === true ? "primary-text-green" : "text-[#D3D3D3]" } flex-shrink-0`}
                  />
                  At least 1 capital letter
                </li>
                <li className={flexitemCentre}>
                  <ResetPasswordRightTick
                    className={`${SetPasswordStyleListSVG} ${
                      hasNumberOrSpecialChar(password) === true? "primary-text-green" : "text-[#D3D3D3]" } flex-shrink-0`}
                  />
                  At least 1 number or special character
                </li>
              </ul>

              <div className={SetPasswordStyleResetDiv}>
                <InputField
                  label="Re-enter password"
                  astrix={true}
                  id="rePassword"
                  disabled={false}
                  required={true}
                  type={!showRePassword ? "text" : "password"}
                  name=""
                  onChange={handleRePasswordChange}
                  value={rePassword}
                  error={errors.rePassword}
                  className={InputFieldClass}
                />

                {showRePassword ? (
                  <CloseEyePasswordIcon
                    onClick={() => setShowRePassword(!showRePassword)}
                    className={SetPasswordStyleSVG}
                  />
                ) : (
                  <OpenEyePasswordIcon
                    onClick={() => setShowRePassword(!showRePassword)}
                    className={SetPasswordStyleSVG}
                  />
                )}
              </div>

              <div className="px-4 h-[5rem]">
              <Button
                onClick={handleConfirm}
                className={` ${setPassButtonClass} ${
                  isContinueDisabled ? "bg-[#8ED9B4]" : "greenbg"
                }`}
              >
                Confirm
              </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
