import CryptoJS from 'crypto-js';
import bcrypt from 'bcryptjs';
export function getTimeDifference(updatedAt) {
  const currentTime = new Date();
  const updatedTime = new Date(updatedAt);

  const timeDifference = currentTime - updatedTime; // difference in milliseconds
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (days > 0) {
    return `Last update ${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `Last update ${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `Last update ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return `Last update ${seconds} second${seconds > 1 ? 's' : ''} ago`;
  }
}
export const encryptPassword = async (password) => {
  const salt = await bcrypt.genSalt(10); // Generate salt with 10 rounds
  const hashedPassword = await bcrypt.hash(password, salt); // Hash the password with the generated salt
  return hashedPassword;
};
export const comparePassword = async (password, hashedPassword) => {
  const isMatch = await bcrypt.compare(password, hashedPassword);
  return isMatch; // Returns true if the password matches the hashed password
};
export function convertToInitials(name) {
  if (!name || typeof name !== 'string') {
    return ''; // Return an empty string if the input is not valid
  }

  const nameParts = name.trim().split(' '); // Split the name by spaces

  if (nameParts.length === 1) {
    // If it's a single name, return the first letter
    return nameParts[0].charAt(0).toUpperCase();
  }

  // Otherwise, get the first letter of each part and combine them
  const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join('');
  return initials;
}
export const onkeyDownforSpecialCharcter = (e) => {
  if (
    e.key === 'e' ||
    e.key === 'E' ||
    e.key === '--' ||
    e.key === '+' ||
    e.key === '!' ||
    e.key === '@' ||
    e.key === '#' ||
    e.key === '$' ||
    e.key === '%' ||
    e.key === '^' ||
    e.key === '&' ||
    e.key === '*' ||
    e.key === '(' ||
    e.key === ')' ||
    e.key === '_' ||
    e.key === '' ||
    e.key === '<' ||
    e.key === '>' ||
    e.key === '/' ||
    e.key == ',' ||
    e.key == '=' ||
    e.key == ':' ||
    e.key == ';' ||
    e.key == '"' ||
    e.key == "'" ||
    e.key === '[' ||
    e.key === ']' ||
    e.key === '{' ||
    e.key === '}' ||
    e.key === '?' ||
    e.key === '|' ||
    e.key === '\\' ||
    e.key === '`' ||
    e.key === '~' ||
    e.key === 'A' ||
    e.key === 'B' ||
    e.key === 'C' ||
    e.key === 'D' ||
    e.key === 'E' ||
    e.key === 'F' ||
    e.key === 'G' ||
    e.key === 'H' ||
    e.key === 'I' ||
    e.key === 'J' ||
    e.key === 'K' ||
    e.key === 'L' ||
    e.key === 'M' ||
    e.key === 'N' ||
    e.key === 'O' ||
    e.key === 'P' ||
    e.key === 'Q' ||
    e.key === 'R' ||
    e.key === 'S' ||
    e.key === 'T' ||
    e.key === 'U' ||
    e.key === 'V' ||
    e.key === 'W' ||
    e.key === 'X' ||
    e.key === 'Y' ||
    e.key === 'Z' ||
    e.key === 'a' ||
    e.key === 'b' ||
    e.key === 'c' ||
    e.key === 'd' ||
    e.key === 'e' ||
    e.key === 'f' ||
    e.key === 'g' ||
    e.key === 'h' ||
    e.key === 'i' ||
    e.key === 'j' ||
    e.key === 'k' ||
    e.key === 'l' ||
    e.key === 'm' ||
    e.key === 'n' ||
    e.key === 'o' ||
    e.key === 'p' ||
    e.key === 'q' ||
    e.key === 'r' ||
    e.key === 's' ||
    e.key === 't' ||
    e.key === 'u' ||
    e.key === 'v' ||
    e.key === 'w' ||
    e.key === 'x' ||
    e.key === 'y' ||
    e.key === 'z'
  ) {
    e.preventDefault() // Prevent the default behavior (i.e., typing 'e' or '-')
  }
}

export const LogOut = () => {
  sessionStorage.clear();
  window.location.href = "/";
}

export const AddJobValidation = (formData, SetError) => {
  const errors = {};

  // Validation criteria
  if (!formData.custom_title) {
    errors.custom_title = "Job title is required";
  }

  // if (!formData.job_description) {
  //   errors.job_description = "Job description is required";
  // }

  // if (!formData.preferred_location && formData?.consultancy_mode !== "" && (
  //   formData?.consultancy_mode === 2 || formData?.consultancy_mode === 3)) {
  //   errors.preferred_location = "Preferred location is required";
  // }

  if (!formData.tenure) {
    errors.tenure = "Tenure is required";
  }

  if (!formData.consultancy_mode) {
    errors.consultancy_mode = "Consultancy mode is required";
  }

  if (formData?.key_service?.length === 0) {
    errors.key_service = "At least one key service is required";
  }

  // if (formData?.core_expertise?.length === 0) {
  //   errors.core_expertise = "At least one core expertise is required";
  // }

  if (formData?.keyAreaServices?.length === 0) {
    errors.keyAreaServices = "At least one key area service is required";
  }

  // Set the errors using the SetError function
  SetError(errors);

  // Return a boolean indicating if the form is valid
  return Object.keys(errors).length === 0;
};

export const HiringFormValidation = (formData, setErrors) => {
  const newErrors = {};

  if (!formData.projectName) {
    newErrors.projectName = 'Project name is required';
  }
  if (!formData.modeofconsultancy) {
    newErrors.modeOfConsultancy = 'Mode of consultancy is required';
  }
  if (!formData.availabilityforhire) {
    newErrors.availabilityForHire = 'Availability for hire is required';
  }
  if (!formData.selectMonth) {
    newErrors.selectMonth = 'Select month is required';
  }
  if (!formData.startDate) {
    newErrors.startDate = 'Start date is required';
  }
  // if (!formData.endDate) {
  //   newErrors.endDate = 'End date is required';
  // }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
}

export const ValidationForMeeting = (formData, setErrors) => {
  let errors = {};

  if (!formData.title) {
    errors.title = "Title is required";
  }
  if (!formData?.preferred_date) {
    errors.preferred_date = "Preferred date is required";
  }
  if (!formData?.email && !formData?.is_virtual) {
    errors.email = "Email is required";
  }
  if (!formData?.preferred_start_time) {
    errors.preferred_start_time = "Preferred start time is required";
  }
  if (!formData?.preferred_end_time) {
    errors.preferred_end_time = "Preferred end time is required";
  }
  if (!formData?.description) {
    errors.description = "Description is required";
  }

  if (!formData?.preferred_date2) {
    errors.preferred_date2 = "Preferred date is required"
  }
  if (!formData?.preferred_start_time2) {
    errors.preferred_start_time2 = "Preferred start time is required"
  }
  if (!formData?.preferred_end_time2) {
    errors.preferred_end_time2 = "Preferred end time is required"
  }

  setErrors(errors);
  return Object.keys(errors).length === 0;
}

export function convertDate(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

export const BankDetailsValidation = (formData, setErrors) => {
  // Perform form validation
  let validationErrors = {};
  if (!formData.account_number) {
    validationErrors.account_number = 'Account number is required';
  }
  if (!formData.holder_name) {
    validationErrors.holder_name = 'Account holder name is required';
  }
  if (!formData.ifsc_code) {
    validationErrors.ifsc_code = 'Bank IFSC is required';
  }
  if (!formData.account_type) {
    validationErrors.account_type = 'Type of account is required';
  }
  setErrors(validationErrors);
  return Object.keys(validationErrors).length === 0;
}

export const createSignature = () => {
  // Fixed string (could be a constant or some predefined data)
  const fixedString = 'fixedStringToSign';

  // Secret key (store this securely in a real application)
  const secretKey = '1234567890abcdef';

  // Combine the fixed string with the secret key
  const combinedString = fixedString + secretKey;

  // Generate hash using SHA-256
  const hash = CryptoJS.SHA256(combinedString);

  // Convert hash to a string
  const signature = hash.toString(CryptoJS.enc.Hex);

  return signature;
};
export const generateSignature = (orderId, paymentId) => {
  const keySecret = 'O8yhoq0jhpVCAk7R5dxlVKU8'; // Replace with your actual key secret
  const message = `${orderId}|${paymentId}`;
  const signature = CryptoJS.HmacSHA256(message, keySecret).toString(CryptoJS.enc.Hex);
  return signature;
};
