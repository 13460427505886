import React, { useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSidebar } from "./SidebarProvider";
import useGetData from "../Hook/useGetData";
import { CloseIcon, ReferralsIcon } from "./Icons";
import { SideNavbarOptionActive, SideNavbarOptionActiveMobile, SideNavbarStyleContainer, SideNavbarStyleContainerMobile, SideNavbarStyleDivPtagtwo, SideNavbarStyleDivPtagtwoGreen, SideNavbarStyleDivTwo } from "./StyleCommon";


const SideNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSidebarOpen, toggleSidebar } = useSidebar();

  const token = JSON.parse(sessionStorage.getItem('userToken')) || "";
  const { data, loading, error, refetch } = useGetData('job/list', token);

  const jobsAvailable = useCallback(() => {
    return Array.isArray(data?.data) && data?.data.length > 0;
  }, [data]);


  return (
    <>
    

      {isSidebarOpen && (
        // <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50">
        <div className="lg:hidden fixed w-full h-full top-0 left-0 right-0 bg-white z-50 flex flex-col justify-start">
          <div onClick={toggleSidebar} className=" w-full py-4 px-4 flex justify-between cursor-pointer">
            <div>
                <h6 className="h6-text text-[#2C2C2C]">
                  Menu
                </h6>
            </div>
            <div>
            <CloseIcon />
            </div>
          </div>
        
          <div className="flex flex-col items-center bg-white h-full overflow-y-auto">
            {/* Sidebar content here */}





         
           
      
            <div
              className={`${location?.pathname === '/dashboard/referrals' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}
              
              onClick={() => {
                navigate("/dashboard/referrals");
                toggleSidebar();
              }}
            >
              <div
                className='rounded-full flex items-center justify-center'
              >
                <ReferralsIcon location={location} />
              </div>
              <div>
                <p className={`${location?.pathname === '/dashboard/profile' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}>Referrals</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="hidden custom-scrollbar fixed lg:flex flex-col items-center overflow-auto h-[91vh] border justify-start px-4 shadow-lg lg:w-[7%] md:w-[7%] bg-white z-50">
        {/* Sidebar content for desktop */}
     
       
        <div
          className={SideNavbarStyleContainer}
          onClick={() => navigate("/dashboard/referrals")}
        >
          <div
            className={
              location?.pathname === "/dashboard/referrals"
                ? `${SideNavbarStyleDivTwo} ${SideNavbarOptionActive}`
                : `${SideNavbarStyleDivTwo}`
            }
          >
            <ReferralsIcon location={location} />
          </div>
          <div>
            <p className={SideNavbarStyleDivPtagtwo}>Referrals</p>
          </div>
        </div>
        <div className="w-full h-[35rem]">


        </div>
      </div>
    </>
  );
};

export default SideNavbar;
