import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import RegisterMain from "../modules/register/RegisterMain";
import SetPassword from "../modules/register/SetPassword";
import Login from "../modules/login/Login";
import Forgot from "../modules/login/Forgot";
import ForgotOTP from "../modules/login/ForgotOTP";
import LoginOTP from "../modules/login/LoginOTP";
import Done from "../modules/login/Done";
import Congratulations from "../modules/register/Congratulations";
import ReferralsDashbaord from "../modules/dashboard/ReferralsDashbaord";
import MyProfile from "../modules/dashboard/MyProfile";
import CompanyNotification from "../modules/dashboard/CompanyNotification";
import ResetPassword from "../common/ResetPassword";
import CompanyResetPassword from "../modules/dashboard/CompanyResetPassword";
const AllRoutes = () => {
  const token = JSON.parse(sessionStorage.getItem("userToken"));


  
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(true);
    const timer = setTimeout(() => {
      setState(false);
    }, 2000);

    return () => clearTimeout(timer); // Clean up the timeout
  }, []);
  
  return (
    <>
      <Routes>
        {!token ? (
          <>
            <Route path="/" element={<Navigate to="/login" replace={true} />} />
            <Route path="/register" element={<RegisterMain />} />
            <Route path="/set-password" element={<SetPassword isBankerSignup={false} />} />
            <Route path="/banker-set-password" element={<SetPassword isBankerSignup={true} />} />
            <Route path="/congratulation" element={<Congratulations />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<Forgot />} />
            <Route path="/forgot-otp" element={<ForgotOTP />} />
            <Route path="/login-otp" element={<LoginOTP />} />
            <Route path="/done" element={<Done />} />
            <Route path="*" element={<Navigate to="/login" replace={true} />} />
            


          </>
        ) : (
          <>
            <Route path="/dashboard/referrals" element={<ReferralsDashbaord />} />
            <Route path="/dashboard/profile" element={<MyProfile />} />
            <Route path="/dashboard/notification" element={<CompanyNotification />} />
            <Route path="/dashboard/reset-password" element={<CompanyResetPassword />} />
            <Route path="*" element={<Navigate to="/dashboard/referrals" replace={true} />} />

            {/* Add authenticated routes here */}
          </>
        )}
      </Routes>
    </>
  );
};

export default AllRoutes;
