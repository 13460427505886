import React from 'react';
import { CheckboxInput, RadioInput, CheckboxLabel, flexitemCentre } from './StyleCommon';

const Checkbox = ({ name, id, label, labelClassName, description, value, checked, onChange, type }) => {
  const inputClass = type === 'checkbox' ? CheckboxInput : RadioInput;

  return (
    <div className={flexitemCentre}>
      <input
        name={name}
        id={id}
        type={type}
        value={value}
        checked={checked}
        onChange={(e) => {
          if (typeof onChange === 'function') {
            onChange(e);
          }
        }}
        className={`${inputClass} !w-[20px] !h-[20px] text-center `}
      />
      <label className={labelClassName ? labelClassName : CheckboxLabel}>
        <div>{label}</div>
        <div>{description}</div>
      </label>
    </div>
  );
};

export default Checkbox;
